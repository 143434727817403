import React from 'react';
import { Link } from 'gatsby';
import footerData from 'data/Saas/footer.json';

const Footer = () => {
  return (
    <footer className="style-5 border-top brd-gray">
      <div className="container">
        <div className="row gx-0 justify-content-between">
          <div className="col-lg-3 col-sm-6">
            <div className="items">
              <div className="title">
                WOXOX CRM Solutions
              </div>
              <small className="text">
                4544 Sheppard Ave E unit 514,
                Scarborough, ON M1S 1V2
                Canada<br /><br/>
                General Hospital Rd, opp. st joseph school, near indian oil pump, Jai Vihar, Kunnukuzhy, Thiruvananthapuram, Kerala 695003
              </small>
              <div className="socail-icons">
                <a rel="noreferrer" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray me-2" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a rel="noreferrer" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray me-2" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a rel="noreferrer" className="icon-35 rounded-circle bg-gray overflow-hidden d-inline-flex align-items-center justify-content-center text-gray" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="items">
              <div className="title">
                Information
              </div>
              {/* <small className="text mb-10 d-block">
                { footerData.address.address1 } <br /> { footerData.address.address2 }
              </small> */}
              <small className="text mb-10 d-block">
                {footerData.phone}
              </small>
              <small className="text d-block">
                {footerData.email}
              </small>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="items">
              <div className="title">
               About WOXOX
              </div>
              <ul>
                {
                  footerData.usefulLinks.map((link, index) => (
                    <li key={index}>
                      <Link to={link.link}>{link.title}</Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="items">
              <div className="title">
                Policy
              </div>
              <ul>
                {
                  footerData.resources.map((link, index) => (
                    <li key={index}>
                    <Link to={link.link}>{link.title}</Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="logo">
                {/* <img src="/assets/img/logo_foot_home5.png" alt="" /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <small className="small">
                © 2025 Copyrights by <a href="#" className="fw-bold text-decoration-underline">WOXOX</a> All Rights Reserved. <a href="https://themeforest.net/user/themescamp" className="fw-bold text-decoration-underline"></a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer